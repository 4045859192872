@import '~app/mixins';

.priceList {
  color: $color-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    background-color: #52a0fd;
    background-image: linear-gradient(#64b5ff 0%, #297ccf 100%);

    &.short {
      height: 185px;
    }
  }

  :not(.gradient) {
    z-index: 100;
  }

  .title {
    color: white;
    margin-top: 15px;
    font-size: 28px;
  }

  svg {
    fill: white;
  }

  .icon {
    height: 70px;
    margin: 20px;
  }

  .pricing {
    .rate {
      display: flex;
      align-items: center;

      &.caption {
        color: white;
        align-items: flex-end;
        font-size: 15px;

        > div {
          padding-bottom: 6px;
          white-space: nowrap;
        }
      }

      > div {
        padding: 10px 0 10px 13px;
        text-align: left;

        &:first-child {
          width: 175px;
          padding-left: 25px;
        }

        &:nth-child(2) {
          width: 130px;
        }

        &:nth-child(3) {
          width: 130px;
        }

        &:last-child {
          width: 140px;
        }
      }
    }

    .rates {
      color: $color-grey;
      background-color: white;
      border: 1px solid $color-grey-lighter;
      box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.16);
      font-size: 13px;
      padding: 3px 3px 10px;
      margin-bottom: 10px;

      .rate {
        &:nth-child(odd) {
          background-color: #f4f4f4;
        }

        > div {
          border-right: 1px solid $color-grey-lighter;
          border-bottom: 1px solid $color-grey-lighter;

          &:first-child {
            @include primary-color();

            font-weight: bold;
          }

          &.bold {
            font-weight: bold;
          }

          &:last-child {
            border-right-style: none;
          }
        }
      }
    }
  }
}
